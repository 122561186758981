import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Layout from './layout';
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Box,
  TextField,
  Link,
  Icon,
  Pagination,
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { Email, Phone, Home } from '@mui/icons-material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';

const placeholderImage = 'static/placeholder.png'; // Ensure this path is correct

const InnovatorsPage = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filter, setFilter] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const usersPerPage = 6;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://api.ghsdginnovations.org/api/users/getAll');
        setUsers(response.data);
        setFilteredUsers(response.data.filter(user => user.role === 'innovator'));
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const filtered = users.filter(user =>
      user.fullName.toLowerCase().includes(filter.toLowerCase())
    );
    setFilteredUsers(filtered.filter(user => user.role === 'innovator'));
    setCurrentPage(1); // Reset to first page on filter change
  }, [filter, users]);

  const handleFilterChange = (event) => {
    setFilter(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);

  return (
    <Layout>
      <Box marginTop='150px' paddingBottom='60px'>
        <Typography 
          variant="h6" 
          gutterBottom 
          style={{
            fontFamily: 'Noto Sans, sans-serif',
            fontWeight: 'bold',
            color: '#0468B1', // A nice shade of blue
            letterSpacing: '1px',
            textTransform: 'uppercase',
          
          }}
        >
          List of Innovators
        </Typography>

        <TextField
          id="filter"
          label="Filter by name"
          value={filter}
          onChange={handleFilterChange}
          fullWidth
          margin="normal"
          variant="outlined"
          style={{ width: '300px', marginBottom:'30px' }}
        />
        <Grid container spacing={3}>
          {currentUsers.map((user) => (
            <Grid item xs={12} sm={6} md={4} key={user._id}>
              <Card>
              <Box display="flex" justifyContent="center" mt={2}>
                <Box
                  component="img"
                  src={user.profilePicture || placeholderImage}
                  alt={user.fullName}
                  sx={{
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                    objectFit: 'cover',
                    border: '2px solid #ddd',
                  }}
                />
              </Box>
                <CardContent>
                  <Typography variant="h6" align="center">{user.fullName}</Typography>
                 
                  <Box display="flex" justifyContent="center" marginBottom={1}>
                    <BusinessIcon fontSize="small" />
                    <Typography variant="body2"  align="center" color="textSecondary" marginLeft={1}>
                      {user.businessName ? user.businessName : "No business name"}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="center" marginBottom={1}>
                    <LocationOnIcon fontSize="small" />
                    <Typography variant="body2"  align="center" color="textSecondary" marginLeft={1}>
                      {user.city  ? user.city  : "No Location"}
                    </Typography>
                  </Box>
                 
                  <Box marginTop={2} align="center">
                    <Link
                      component={RouterLink}
                      to={`/innovator/${user._id}`}
                      underline="none"
                    >
                      View Details
                    </Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box display="flex" justifyContent="center" marginTop={3}>
          <Pagination
            count={Math.ceil(filteredUsers.length / usersPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Box>
    </Layout>
  );
};

export default InnovatorsPage;
