import React, { useState, useEffect } from 'react';
import { TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle, Snackbar, CircularProgress } from '@mui/material';
import axios from "../../helpers/axios";

const EditInnovationForm = ({ innovation, open, onClose, onUpdate }) => {
  const [formValues, setFormValues] = useState({
    innovationName: '',
    description: '',
    problemStatement: '',
    solution: '',
    tractionAndImpact: '',
    planForExpansion: ''
  });

  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [loading, setLoading] = useState(false); // State for progress bar

  useEffect(() => {
    if (innovation) {
      setFormValues({
        innovationName: innovation.innovationName || '',
        description: innovation.description || '',
        problemStatement: innovation.problemStatement || '',
        solution: innovation.solution || '',
        tractionAndImpact: innovation.tractionAndImpact || '',
        planForExpansion: innovation.planForExpansion || ''
      });
    }
  }, [innovation]);

  const handleChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async () => {
    setLoading(true); // Show progress bar while submitting
    try {
      const response = await axios.put(`/innovations/${innovation._id}`, formValues);
      onUpdate(response.data);
      setSuccessMessageOpen(true);
      onClose();
    } catch (error) {
      console.error('Error updating innovation:', error);
    }
    setLoading(false); // Hide progress bar after submission
  };

  const handleSuccessMessageClose = () => {
    setSuccessMessageOpen(false);
  };


  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Innovation</DialogTitle>
      <DialogContent>
        <TextField
          margin="dense"
          label="Innovation Name"
          type="text"
          fullWidth
          name="innovationName"
          value={formValues.innovationName}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Description"
          type="text"
          fullWidth
          name="description"
          value={formValues.description}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Problem Statement"
          type="text"
          fullWidth
          name="problemStatement"
          value={formValues.problemStatement}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Solution"
          type="text"
          fullWidth
          name="solution"
          value={formValues.solution}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Traction and Impact"
          type="text"
          fullWidth
          name="tractionAndImpact"
          value={formValues.tractionAndImpact}
          onChange={handleChange}
        />
        <TextField
          margin="dense"
          label="Plan for Expansion"
          type="text"
          fullWidth
          name="planForExpansion"
          value={formValues.planForExpansion}
          onChange={handleChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <div style={{ position: 'relative' }}>
          <Button onClick={handleSubmit} color="primary" disabled={loading}>
            Save
          </Button>
          {loading && <CircularProgress size={24} style={{ position: 'absolute', top: '50%', left: '50%', marginTop: -12, marginLeft: -12 }} />}
        </div>
      </DialogActions>
      <Snackbar
        open={successMessageOpen}
        autoHideDuration={3000}
        onClose={handleSuccessMessageClose}
        message="Innovation updated successfully"
      />
    </Dialog>
  );
};

export default EditInnovationForm;