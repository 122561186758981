import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import Layout from '../pages/home/layout';
const HelpPage = () => {
  return (
     <Layout>
    <Container sx={{ marginTop: '120px',  marginTop: '120px' }}>
      <Box>
      <Typography 
  variant="h6" 
  gutterBottom 
  style={{
    fontFamily: 'Noto Sans, sans-serif',
    fontWeight: 'bold',
    color: '#0468B1', // A nice shade of blue
    letterSpacing: '1px',
    textTransform: 'uppercase',
    marginBottom: '15px',
  }}
>
          Help and Support
        </Typography>
        <hr/>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
        Welcome to the help page tailored for partners. Here, you can find answers to frequently asked questions and guidance on how to effectively utilize your role within the application.
</Typography>

        <Typography  gutterBottom style={{ fontWeight: 'bold' }}> 
          Frequently Asked Questions
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
          <strong>Q1:</strong> How do I view innovations for potential collaboration?<br />
          <strong>A:</strong>Navigate to the "Innovations" page to browse and view all listed innovations. You can filter innovations to find those that align with your collaboration interests.
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
          <strong>Q2:</strong>How can I offer collaboration opportunities or feedback on an innovation?<br />
          <strong>A:</strong>Once you find an innovation that interests you, click on it to view more details. In the comments section, you can offer partnership opportunities, share your thoughts, or provide feedback to help improve the project.
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
          <strong>Q3:</strong>How can I share or access resources?<br />
          <strong>A:</strong> Partners can both share and access resources through the "Resources" section of the portal. Here, you can contribute materials that may benefit innovators and other stakeholders, or utilize available resources to support your collaboration efforts.


        </Typography>
        
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          Contact Support
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
        If you need further assistance or have specific questions, please contact our support team at <b>support@ghsdginnovations.org</b>. We’re here to help you make the most of your partnership role.
        </Typography>
      </Box>
    </Container>
    <br/>
    </Layout>
  );
};

export default HelpPage;
