import React, { useState, useEffect, useRef } from 'react';
import {  Box, Grid, Typography } from '@mui/material';
import axios from 'axios';
import * as am5 from '@amcharts/amcharts5';
import * as am5percent from '@amcharts/amcharts5/percent';
import AnimatedTheme from '@amcharts/amcharts5/themes/Animated'; // Correct impocvrt
import Layout from './layout';
import PieChart from './pieChart'
import GhanaMap from './map';


const Dashboard = () => {
  



  return (
    <Layout>
    <Box marginTop='150px' marginBottom='100px'>
      <Grid container spacing={4}>
        {/* First Column for Pie Chart */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Innovations by Sectors
          </Typography>
          <PieChart />
        </Grid>

        {/* Second Column for Ghana Map */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>
            Innovations by Regions
          </Typography>
          <GhanaMap />
        </Grid>
      </Grid>
    </Box>
  </Layout>
  );
};

export default Dashboard;
