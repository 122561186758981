import React from 'react';

import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => {
  return (
    <div style={{ display: 'flex', minHeight: '100vh' }}>
      <Header />
      <div style={{ display: 'flex', flexGrow: 1 }}>
        
        <main style={{ flexGrow: 1, padding: '20px', boxSizing: 'border-box' }}>
          {children}

        </main>
        <Footer/>
      </div>
    </div>
  );
};

export default Layout;
