import React, { useEffect, useState } from 'react';
import axios from '../../helpers/axios';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Avatar, Typography, Button, Card, CardContent, Box, CardMedia, CardActionArea, CardActions, IconButton } from "@mui/material";
import { Link } from 'react-router-dom';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useDispatch, useSelector } from 'react-redux';
import { isUserLoggedIn } from '../../actions/auth.actions';
import Layout from './layout';
const placeholderImage = 'static/placeholder.jpg';

const FavoritedInnovations = ({ userId }) => {
  const [favoritedInnovations, setFavoritedInnovations] = useState([]);
  const [error, setError] = useState(null);
  const [visibleInnovations, setVisibleInnovations] = useState(6);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [userDetails, setUserDetails] = useState({});
  const [userRole, setUserRole] = useState('');
  const [liked, setLiked] = useState(false);
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);

  const handleSearch = (e) => {
    const keyword = e.target.value.toLowerCase();
    setSearchKeyword(keyword);
    setVisibleInnovations(6);
  };

  const loadMore = () => {
    setVisibleInnovations(prevVisible => prevVisible + 6);
  };

  const truncateWords = (text, limit) => {
    const words = text.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    } else {
      return text;
    }
  };

  const handleLike = async (id) => {
    try {
        await axios.put(`/innovations/${id}/favorite`);
        setLiked(!liked);
        // Auto refresh the page
        window.location.reload();
    } catch (error) {
        console.error('Error liking the innovation:', error);
    }
};


  const shareToPlatform = (platform, innovation) => {
    const shareUrl = `${window.location.origin}/innovation/${innovation._id}`;
    let platformShareUrl = '';
    let innovationTitle = innovation.innovationName;
    let innovationExcerpt = truncateWords(innovation.description, 25);
    let innovationImage = innovation.featuredImg || placeholderImage;

    switch (platform) {
      case 'facebook':
        platformShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`;
        break;
      case 'twitter':
        platformShareUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(innovationTitle)}&via=YourTwitterHandle`;
        break;
      case 'linkedin':
        platformShareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}&title=${encodeURIComponent(innovationTitle)}&summary=${encodeURIComponent(innovationExcerpt)}&https://app.ghsdginnovations.org`;
        break;
      default:
        break;
    }

    if (platformShareUrl !== '') {
      window.open(platformShareUrl, '_blank');
    }
  };

  const fetchUserDetails = async (userId) => {
    try {
      const response = await fetch(`https://api.ghsdginnovations.org/api/users/${userId}`);
      if (!response.ok) {
        throw new Error('Failed to fetch user details');
      }
      const userDetails = await response.json();
      return userDetails;
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const fetchUserRole = async (userId) => {
    try {
      const response = await axios.get(`/users/role/${userId}`);
      if (response.status === 200) {
        setUserRole(response.data.role);
      }
    } catch (error) {
      console.error('Failed to fetch user role:', error);
    }
  };

  useEffect(() => {
    const fetchAndSetUserDetails = async () => {
      const userDetailsMap = {};
      for (const innovation of favoritedInnovations) {
        if (!userDetails[innovation.userId]) {
          const details = await fetchUserDetails(innovation.userId);
          if (details) {
            userDetailsMap[innovation.userId] = details;
          }
        }
      }
      setUserDetails(prevDetails => ({ ...prevDetails, ...userDetailsMap }));
    };

    fetchAndSetUserDetails();
  }, [favoritedInnovations]);

  useEffect(() => {
    dispatch(isUserLoggedIn());
  }, [dispatch]);

  useEffect(() => {
    if (auth.userId) {
      fetchUserRole(auth.userId);
    }
  }, [auth.userId]);

  useEffect(() => {
    const fetchFavoritedInnovations = async () => {
      try {
        const response = await axios.get(`/innovations/favorited/${userId}`);
        const innovationIds = response.data.favoritedInnovations;

        // Fetch details for each innovation ID
        const innovationDetails = await Promise.all(
          innovationIds.map(async id => {
            try {
              const response = await axios.get(`/innovations/${id}`);
              return response.data;
            } catch (err) {
              console.error(`Error fetching innovation ${id}:`, err);
              return null; // Skip this innovation if there's an error
            }
          })
        );

        // Filter out any null values
        const validInnovations = innovationDetails.filter(innovation => innovation !== null);
        setFavoritedInnovations(validInnovations);
      } catch (error) {
        console.error('Error fetching favorited innovations:', error);
        setError('Failed to fetch favorited innovations. Please try again later.');
      }
    };

    fetchFavoritedInnovations();
  }, [userId]);

  return (

    <Layout>
       <Box marginTop='100px' marginBottom='100px' paddingBottom='100px' overflowY='scroll' maxHeight='calc(100vh - 124px)'>
       <Typography 
          variant="h6" 
          gutterBottom 
          style={{
            fontFamily: 'Noto Sans, sans-serif',
            fontWeight: 'bold',
            color: '#0468B1', // A nice shade of blue
            letterSpacing: '1px',
            textTransform: 'uppercase',
            marginBottom: '15px',
          }}
        >
        Favourited Innovations
        </Typography>
    <div style={{ marginTop: '10px', marginBottom: '100px', alignItems: 'center' }}>
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))', gap: '10px', marginBottom: '20px' }}>
        {favoritedInnovations.length > 0 ? (
          favoritedInnovations.slice(0, visibleInnovations).map((innovation) => (
            <Card key={innovation._id}>
              <Link to={`/innovation/${innovation._id}`} style={{ textDecoration: 'none' }}>
                <div className="bg-white rounded-lg p-6">
                  <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={innovation.featuredImg || placeholderImage}
                    alt={innovation.innovationName}
                  />
                    <CardContent >
                    <Typography component="div">
                      {innovation.innovationName}
                    </Typography>
                      <hr className="h-1 border-blue" />
                      <Typography variant="body2" color="text.secondary" sx={{ overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3 }}>
                        {truncateWords(innovation.description, 20)}
                      </Typography>
                      <div style={{ display: 'flex', alignItems: 'center', marginTop: '5px', marginBottom: '0px', marginRight: '10px', marginLeft: '20px', textAlign: 'center' }}>
                        <div style={{ marginLeft: 'auto' }}>
                          <Typography variant="body2" color="#0468B1">
                            know more
                          </Typography>
                        </div>
                      </div>
                    </CardContent>
                  </CardActionArea>
                </div>
              </Link>
             
              <CardActions>
                <IconButton onClick={() => shareToPlatform('facebook', innovation)} color="primary">
                  <FacebookIcon />
                </IconButton>
                <IconButton onClick={() => shareToPlatform('twitter', innovation)} color="primary">
                  <TwitterIcon />
                </IconButton>
                <IconButton onClick={() => shareToPlatform('linkedin', innovation)} color="primary">
                  <LinkedInIcon />
                </IconButton>
                <Button
                  size="small"
                  variant="contained"
                  color={liked ? "primary" : "secondary"}
                  startIcon={liked ?  <FavoriteBorderIcon /> :  <FavoriteIcon />}
                  onClick={() => handleLike(innovation._id)}
                >
                </Button>
              </CardActions>
            </Card>
          ))
        ) : (
          <Typography  color="textSecondary"style={{ color: '#0468B1', display: 'flex', alignItems: 'center' }}>
            No favorite innovations available.
          </Typography>
        )}
      </div>

      {visibleInnovations < favoritedInnovations.length && (
        <Button onClick={loadMore} color="primary">
          Load More
        </Button>
      )}
    </div>
   
    <br/>
    <br/>
    </Box>
    </Layout>
  );
};

export default FavoritedInnovations;
