import React, { useEffect, useState } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5map from "@amcharts/amcharts5/map";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

// Complete data mapping
const regionEndpoints = {
  "Eastern": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66543ae74a6cefd9e30c727b",
  "Greater Accra": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66543b194a6cefd9e30c727e",
  "Ashanti": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66543b214a6cefd9e30c7280",
  "Volta": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66543b2a4a6cefd9e30c7282",
  "Northern": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66543b3f4a6cefd9e30c7284",
  "North East": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66543b444a6cefd9e30c7286",
  "Oti": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66543b704a6cefd9e30c7288",
  "Western": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66654be5762b720507db152b",
  "Savannah": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66654c45a58e1705a562d448",
  "Upper West": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66654c51a58e1705a562d44a",
  "Upper East": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66654c6ba3971705dfda66d4",
  "Bono": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66654db8a3971705dfda66de",
  "Bono East": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66654dc0a3971705dfda66e0",
  "Ahafo": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66654dcda3971705dfda66e2",
  "Central": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66654e03a3971705dfda66e4",
  "Western North": "https://api.ghsdginnovations.org/api/innovations/getTotalByRegionAndStatus/66654e15a3971705dfda66e6"
};

const regionMapIds = {
  "Eastern": "GH-EP",
  "Greater Accra": "GH-AA",
  "Ashanti": "GH-AH",
  "Volta": "GH-TV",
  "Northern": "GH-NP",
  "North East": "GH-NE",
  "Oti": "GH-OT",
  "Western": "GH-WP",
  "Savannah": "GH-SV",
  "Upper West": "GH-UW",
  "Upper East": "GH-UE",
  "Bono": "GH-BO",
  "Bono East": "GH-BE",
  "Ahafo": "GH-AF",
  "Central": "GH-CP",
  "Western North": "GH-WN"
};

const regionApiMapping = Object.fromEntries(
  Object.entries(regionMapIds).map(([regionName, regionId]) => [regionId, regionEndpoints[regionName]])
);

const GhanaMap = () => {
  const [data, setData] = useState([]);
  const [chart, setChart] = useState(null);

  useEffect(() => {
    async function fetchAllData() {
      try {
        const endpointPromises = Object.entries(regionApiMapping).map(([regionId, url]) => 
          fetch(url)
            .then(res => res.json())
            .then(json => ({
              id: regionId,
              totalInnovations: json.totalInnovations || 0,
              statusCounts: json.statusCounts || []
            }))
        );

        const results = await Promise.all(endpointPromises);
        setData(results);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchAllData();
  }, []);

  useEffect(() => {
    if (data.length === 0) return;

    const root = am5.Root.new('chartdiv');
    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(
      am5map.MapChart.new(root, {
        panX: 'rotateX',
        projection: am5map.geoMercator(),
        layout: root.horizontalLayout,
      })
    );

    const polygonSeries = chart.series.push(
      am5map.MapPolygonSeries.new(root, {
        calculateAggregates: true,
        valueField: 'value',
      })
    );

    polygonSeries.mapPolygons.template.setAll({
      tooltipText: '{name} Total Innovation(s): {value}\n{statusText}', // Update tooltipText
      interactive: true,
      fill: am5.color(0x4CAF50),
    });

    polygonSeries.mapPolygons.template.states.create('hover', {
      fill: am5.color(0x388E3C),
    });

    polygonSeries.set('heatRules', [
      {
        target: polygonSeries.mapPolygons.template,
        dataField: 'value',
        min: am5.color(0xA5D6A7),
        max: am5.color(0x2E7D32),
        key: 'fill',
      },
    ]);

    polygonSeries.mapPolygons.template.states.create('active', {
      fill: am5.color(0x4CAF50),
    });

  polygonSeries.mapPolygons.template.setAll({
  tooltip: am5.Tooltip.new(root, {
    getFillFromSprite: false,
    getStrokeFromSprite: true,
    getLabelFillFromSprite: false,
    autoTextColor: false,
    labelText: "{name} \n\nTotal Innovation(s): {value}\n\n{statusText}", // Tooltip text
    background: am5.RoundedRectangle.new(root, {
      fill: am5.color(0x000000),
      fillOpacity: 0.7,
    }),
    label: {
      fill: am5.color(0xFFFFFF),
      textAlign: "middle", // Center-align text horizontally
      textBaseline: "middle", // Center-align text vertically
      fontSize: 14, // Font size
      textWrap: true, // Enable text wrapping if needed
      fontWeight: "bold", // Make text bold
      paddingLeft: 10, // Optional padding for better readability
      paddingRight: 10, // Optional padding for better readability
      paddingTop: 10, // Optional padding for better readability
      paddingBottom: 10, // Optional padding for better readability
    },
  }),
  adapter: {
    // Function to transform tooltip text before displaying
    tooltipText: (text, target) => {
      // Capitalize and bold the region name
      const parts = text.split('\n');
      const name = parts[0].toUpperCase(); // Capitalize the region name
      return `${name}\n\nTotal Innovations: ${parts[1]}\n\n${parts.slice(2).join('\n')}`;
    }
  }
});


    const heatLegend = chart.children.push(
      am5.HeatLegend.new(root, {
        orientation: 'vertical',
        startColor: am5.color(0xA5D6A7),
        endColor: am5.color(0x2E7D32),
        startText: 'Lowest',
        endText: 'Highest',
        stepCount: 5,
      })
    );

    heatLegend.startLabel.setAll({
      fontSize: 12,
      fill: heatLegend.get('startColor'),
    });

    heatLegend.endLabel.setAll({
      fontSize: 12,
      fill: heatLegend.get('endColor'),
    });

    polygonSeries.events.on('datavalidated', () => {
      heatLegend.set('startValue', polygonSeries.getPrivate('valueLow'));
      heatLegend.set('endValue', polygonSeries.getPrivate('valueHigh'));
    });

    function processData(data, geodata) {
      return geodata.features.map((feature) => {
        const regionData = data.find(d => d.id === feature.id) || { totalInnovations: 0, statusCounts: [] };
        const statusText = regionData.statusCounts.map(statusCount => `${statusCount.status}: ${statusCount.count}`).join('\n');
        return {
          id: feature.id,
          value: regionData.totalInnovations,
          statusText: statusText
        };
      });
    }

    function loadGeodata() {
      chart.set('projection', am5map.geoMercator());

      am5.net.load('https://cdn.amcharts.com/lib/5/geodata/json/ghanaHigh.json').then((result) => {
        const geodata = am5.JSONParser.parse(result.response);

        const processedData = processData(data, geodata);

        polygonSeries.set('geoJSON', geodata);
        polygonSeries.data.setAll(processedData);

        chart.seriesContainer.children.push(
          am5.Label.new(root, {
            x: 5,
            y: 5,
            text: '',
            background: am5.RoundedRectangle.new(root, {
              fill: am5.color(0xffffff),
              fillOpacity: 0.2,
            }),
          })
        );
      }).catch((error) => {
        console.error("Error loading geodata:", error);
      });
    }

    loadGeodata();

    setChart(chart);

    return () => {
      root.dispose();
    };
  }, [data]); // Dependency array with data

  return (
    <div>
       
      <div id="chartdiv" style={{ width: '100%', height: '450px' }}></div>
    </div>
  );
};

export default GhanaMap;